import { css, createGlobalStyle } from 'styled-components'

//fonts
import icons1 from 'assets/fonts/iconfont.ttf';
import icons2 from 'assets/fonts/iconfont.woff';
import icons3 from 'assets/fonts/iconfont.woff2';

const icons = css`
  @font-face {
    font-family: 'iconfont';
    src: url(${icons1}) format('woff2'),
         url(${icons2}) format('woff'),
         url(${icons3}) format('truetype');
  }
`;

export const Icons = createGlobalStyle`${icons}`;

export default icons;