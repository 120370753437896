import { css, createGlobalStyle } from 'styled-components'
import { darken, rem } from 'polished';
import vars from 'components/styles/varss';
//fonts
import ModernEraRegular1 from 'assets/fonts/ModernEra-Regular.woff2';
import ModernEraRegular2 from 'assets/fonts/ModernEra-Regular.woff';
import ModernEraRegular3 from 'assets/fonts/ModernEra-Regular.ttf';
import ModernEraBold1 from 'assets/fonts/ModernEra-Bold.woff2';
import ModernEraBold2 from 'assets/fonts/ModernEra-Bold.woff';
import ModernEraBold3 from 'assets/fonts/ModernEra-Bold.ttf';
import ModernEraExtraBold1 from 'assets/fonts/ModernEra-ExtraBold.woff2';
import ModernEraExtraBold2 from 'assets/fonts/ModernEra-ExtraBold.woff';
import ModernEraExtraBold3 from 'assets/fonts/ModernEra-ExtraBold.ttf';

const typography = css`
  /* Modern Era */
  @font-face {
    font-family: 'Modern Era';
    src: url(${ModernEraRegular1}) format('woff2'),
         url(${ModernEraRegular2}) format('woff'),
         url(${ModernEraRegular3}) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Modern Era';
    src: url(${ModernEraBold1}) format('woff2'),
         url(${ModernEraBold2}) format('woff'),
         url(${ModernEraBold3}) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Modern Era';
    src: url(${ModernEraExtraBold1}) format('woff2'),
        url(${ModernEraExtraBold2}) format('woff'),
        url(${ModernEraExtraBold3}) format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
  }
  /* My Typography Styles */
  body {
    font-size: ${vars.fonts.fontSize};
    font-family: ${vars.fonts.fontFamily};
    font-weight: ${vars.fonts.fontWeight};
    line-height:  ${vars.fonts.lineHeight};
    color: ${vars.colors.text};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  a,
  button {
    transition: ${vars.transitions.hover2};
    cursor: pointer;
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      //outline: none;
    }
    &:active,
    &:focus {
      outline: transparent solid 1px;
    }
    &:active {
      &:focus {
        outline: transparent solid 1px;
      }
    }
    &[x-apple-data-detectors] {
      color: inherit !important;
      text-decoration: none !important;
      font-size: inherit !important;
      font-family: inherit !important;
      font-weight: inherit !important;
      line-height: inherit !important;
    }
  }
  a {
    color: ${vars.colors.blue};
    &:hover,
    &:active,
    &:focus {
      color: ${darken(0.2, vars.colors.blue)};
    }
  }
  p {
    margin-bottom: 0;
    + p {
      margin-top: ${rem('20px')};
    }
    &.p1 {
      font-size: ${rem('20px')};
      line-height: 1.6;
    }
    &.p2 {
      font-size: ${rem('18px')};
      line-height: 1.56;
    }
    &.p3 {
      font-size: ${rem('16px')};
      line-height: 1.5;
    }
    &.p4 {
      font-size: ${rem('14px')};
      line-height: 1.7;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    font-weight: 800;
    margin-bottom: ${rem('20px')};
  }
  h1 {
     font-size: ${rem('64px')};
     line-height: 1.19;
     letter-spacing: -0.02em;
  }
  h2 {
    font-size: ${rem('52px')};
    line-height: 1.15;
    letter-spacing: -0.02em;
  }
  h3 {
    font-size: ${rem('40px')};
    line-height: 1.2;
    letter-spacing: -0.01em;
  }
  h4 {
    font-size: ${rem('32px')};
    line-height: 1.12;
    letter-spacing: -0.01em;
  }
  h5 {
    font-size: ${rem('28px')};
    line-height: 1.29;
    letter-spacing: -0.01em;
  }
  h6 {
    font-size: ${rem('24px')};
    line-height: 1.33;
    letter-spacing: -0.01em;
  }
  a[href="#"], a[href="/#"] {
      pointer-events: none;
  }
`;

export const Typography = createGlobalStyle`${typography}`;

export default typography;