import React, { useEffect } from 'react';
//gatsby
import { useStaticQuery, graphql } from 'gatsby';
//router
import { useLocation } from '@reach/router';
//helmet
import { Helmet } from "react-helmet";
// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
//import fancybox
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
//highlight js
import hljs from 'highlight.js';

const OtherScripts = () => {

  //get current route
  let location = useLocation();

  //re-run script every route change
  useEffect(() => {
    let testimonialsSliders = document.querySelectorAll('.testimonials-slider');
    testimonialsSliders.forEach((sliderInstance, index) => {
      sliderInstance.classList.add('testimonials-slider' + index);
      sliderInstance = new Swiper('.testimonials-slider' + index, {
        loop: true,
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
        // Navigation arrows
        navigation: {
          nextEl: `.testimonials-slider${index} .next-slide-btn`,
          prevEl: `.testimonials-slider${index} .prev-slide-btn`,
        },
        autoHeight: true,
      });
    });
    // Resources Slider
    const placeArrows = (sliderInstance, topPosition) => {
      const nextBtn = sliderInstance.el.closest('.resources-slider-wrapper').querySelector('.next-slide-btn');
      const prevBtn = sliderInstance.el.closest('.resources-slider-wrapper').querySelector('.prev-slide-btn');
      nextBtn.style.top = topPosition + 'px';
      prevBtn.style.top = topPosition + 'px';
    }
    let resourcesSliders = document.querySelectorAll('.resources-slider');
    resourcesSliders.forEach((sliderInstance, index) => {
      sliderInstance.classList.add('resources-slider' + index);
      sliderInstance = new Swiper('.resources-slider' + index, {
        a11y: {
          enabled: false,
        },
        // loop: true,
        slidesPerView: 1,
        spaceBetween: 15,
        // Navigation arrows
        navigation: {
          nextEl: sliderInstance.closest('.resources-slider-wrapper').querySelector('.next-slide-btn'),
          prevEl: sliderInstance.closest('.resources-slider-wrapper').querySelector('.prev-slide-btn'),
        },
        init: false, // will not initialize the slider until we do programmatically
        breakpoints: {
          480: {
            slidesPerView: 1.4,
            spaceBetween: 20
          },
          600: {
            slidesPerView: 2.2,
            spaceBetween: 20
          },
          880: {
            slidesPerView: 3.2,
            spaceBetween: 32
          },
          1480: {
            slidesPerView: 4.2,
            spaceBetween: 32
          }
        }
      });
      sliderInstance.on('beforeResize', function () {
        let slideFeatHeight = sliderInstance.el.querySelector('.resources-slide__feat').clientHeight;
        placeArrows(sliderInstance, parseInt(slideFeatHeight / 2))
      });
      sliderInstance.on('init', function () {
        let slideFeatHeight = sliderInstance.el.querySelector('.resources-slide__feat').clientHeight;
        placeArrows(sliderInstance, parseInt(slideFeatHeight / 2))
      });
      // init Swiper
      sliderInstance.init();
    });

    // Team Carousel Slider
    let teamSliders = document.querySelectorAll('.team-carousel');
    teamSliders.forEach((sliderInstance, index) => {
      sliderInstance.classList.add('team-carousel' + index);
      sliderInstance = new Swiper('.team-carousel' + index, {
        slidesPerView: 1.6,
        spaceBetween: 20,
        autoplay: {
          delay: 1,
        },
        speed: 4000,
        loop: true,
        allowTouchMove: false,
        grabCursor: false,
        centeredSlides: true,
        a11y: {
          enabled: false,
        },
        // Responsive breakpoints
        breakpoints: {
          480: {
            slidesPerView: 2.4,
            spaceBetween: 25,
          },
          767: {
            slidesPerView: 3.2,
            spaceBetween: 32,
          },
          1024: {
            slidesPerView: 4.5,
            spaceBetween: 32,
          },
          1300: {
            slidesPerView: 4.5,
            spaceBetween: 32,
          },
          1480: {
            slidesPerView: 5.5,
            spaceBetween: 32,
          }
        }
      });
    });

    // Images Carousel Slider
    let imagesSliders = document.querySelectorAll('.images-carousel');

    imagesSliders.forEach((sliderInstance, index) => {

      sliderInstance.classList.add('images-carousel' + index);

      sliderInstance = new Swiper('.images-carousel' + index, {
        slidesPerView: 'auto',
        spaceBetween: 20,
        autoplay: {
          delay: 1,
        },
        speed: 4000,
        loop: true,
        loopedSlides: 2,
        allowTouchMove: false,
        grabCursor: false,
        centeredSlides: true,
        // Responsive breakpoints
        breakpoints: {
          1024: {
            spaceBetween: 32,
          },
        }
      });

      // force slider autostart (sometimes it takes a while to start on mobile)
      sliderInstance.slideNext(4000, true);
      sliderInstance.autoplay.start();
    });

    // FAQS
    const FAQsFunc = (e) => {
      if (e.target.closest('.single-faq__header')) {

        let faq = e.target.closest('.single-faq');
        let content = faq.querySelector('.single-faq__content');
        let maxHeight = content.scrollHeight;

        if (faq.getAttribute("aria-expanded") === "true") {
          faq.setAttribute('aria-expanded', "false");
          content.style.display = "none";
        } else {
          faq.setAttribute('aria-expanded', "true");
          content.style.display = "block";
        }
      }
    }
    document.addEventListener('click', FAQsFunc);

    // Pricing
    const pricingFunc = (e) => {
      const PRICING_HEIGHT_LIMIT = window.innerWidth > 992 ? 800 : 700; // the max-height value we want to set
      if (e.target.closest('.show-more-btn')) {
        let btn = e.target.closest('.show-more-btn');
        let btnText = btn.closest('.show-more-btn').querySelector('span');
        let expandableElement = btn.closest('.is-expandable'); // find the parent element that should expand
        let maxHeight = expandableElement.scrollHeight;
        let pricingSectionExpanded = expandableElement.classList.contains('is-expanded');
        if (!pricingSectionExpanded) {
          expandableElement.classList.add('is-expanded');
          btnText.textContent = 'Show Less';
          expandableElement.style.maxHeight = maxHeight + 100 + 'px';
        } else {
          expandableElement.classList.remove('is-expanded');
          btnText.textContent = 'Show All Features';
          expandableElement.style.maxHeight = PRICING_HEIGHT_LIMIT + 'px';
        }
      }
    };
    document.addEventListener('click', pricingFunc);

    //fancybox
    Fancybox.bind("[data-fancybox]");

    // SMOOTH SCROLLING JS
    const getOffset = el => {
      const rect = el.getBoundingClientRect();
      return {
        left: rect.left + window.scrollX,
        top: rect.top + window.scrollY
      };
    }

    const smoothScrollingTo = target => {
      let targetElement = document.querySelector(target); // find the target element
      if (!targetElement) return false;

      let { top: elementTopPosition } = getOffset(targetElement);
      let headerOffset = 150; // fixed header height plus some more space
      let offsetPosition = elementTopPosition - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }

    // anchor link click
    document.querySelectorAll('a[href*=\\#]').forEach(link => {
      link.addEventListener('click', e => {
        let linkEl = e.target.closest('a');
        if (!linkEl.hash) return;

        if (document.querySelector(linkEl.hash)) {
          smoothScrollingTo(linkEl.hash);
          e.preventDefault();
        }
      })
    });

    
    // COLLAPSIBLE CONTENT JS
    const collapseToggle = (e) => {
      if (e.target.closest('.collapsible-content__header')) {
        const collapsibleWrapper = e.target.closest('.collapsible-content-wrapper');
        // const content = collapsibleWrapper.querySelector('.collapsible-content');
        collapsibleWrapper.classList.toggle('opened');
      }
    };
    document.addEventListener('click', collapseToggle);

    // HIGHLIGHT JS
    hljs.highlightAll();

    // Accebility
    // (function(d){var s = d.createElement("script");s.setAttribute("data-account", "l4qy41EPlm");s.setAttribute("src", "https://cdn.userway.org/widget.js");(d.body || d.head).appendChild(s);})(document)

    //cleanup
    return () => {
      document.removeEventListener('click', FAQsFunc);
      document.removeEventListener('click', pricingFunc);
      document.removeEventListener('click', collapseToggle);
    };
    
  }, [location]);


  /////////////////////////////////////////////////////////////////

  //graphql query
  const data = useStaticQuery(graphql`
    query {
      wp {
        trackingInfo {
          header_area_scripts_src
          header_area_scripts_js
        }
      }
    }
  `);

  const headJsSrc = JSON.parse(data.wp.trackingInfo.header_area_scripts_src);
  const headJsScript = JSON.parse(data.wp.trackingInfo.header_area_scripts_js);

  const newScripts1 = Array.from(headJsSrc, (script => ({
    'type': 'text/javascript',
    'src': script
  })));

  const newScripts2 = Array.from(headJsScript, (script => ({
    'type': 'text/javascript',
    'innerHTML': script
  })));

  const newScripts = newScripts1.concat(newScripts2);

  return (
    <>
      <Helmet
        script={newScripts}
      />
    </>
  );
};

export default OtherScripts;