//styles
import styled from 'styled-components';
import vars from 'components/styles/varss';

const Container = styled.div`
  width: 100%;
  max-width: ${props => props.maxWidth ? props.maxWidth : vars.maxWidth};
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
  @media(min-width: ${vars.media.xxlMin}) {
    max-width: ${props => props.maxWidth ? `calc(${props.maxWidth} + 208px)` : `calc(${vars.maxWidth} + 208px)`};
  }
`;

export default Container;