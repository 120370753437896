//styles
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import vars from 'components/styles/varss';

//styled
const Heading = styled.h1`
  font-weight: ${props => props.weight ? props.weight : '800'};
  line-height: ${props => props.lineHeight ? props.lineHeight : 1.2};
  margin-top: ${props => props.marginTop ? rem(props.marginTop) : null};
  margin-bottom: ${props => props.marginBottom ? rem(props.marginBottom) : rem('20px')};
  font-size: ${rem('64px')};
  letter-spacing: -0.02em;
  /* @media (max-width: ${vars.media.lgMax}) {
    font-size: ${rem('64px')};
  } */
  /* Heading1 */
  ${props => props.heading1 && css`
    font-size: ${rem('64px')};
    line-height: 1.19;
    letter-spacing: -0.02em;
  `}
  /* Heading2 */
  ${props => props.heading2 && css`
    font-size: ${rem('52px')};
    line-height: 1.15;
    letter-spacing: -0.02em;
  `}
  /* Heading3 */
  ${props => props.heading3 && css`
    font-size: ${rem('40px')};
    line-height: 1.2;
    letter-spacing: -0.01em;
  `}
  /* Heading4 */
  ${props => props.heading4 && css`
    font-size: ${rem('32px')};
    line-height: 1.12;
    letter-spacing: -0.01em;
  `}
  /* Heading5 */
  ${props => props.heading5 && css`
    font-size: ${rem('28px')};
    line-height: 1.29;
    letter-spacing: -0.01em;
  `}
  /* Heading6 */
  ${props => props.heading6 && css`
    font-size: ${rem('24px')};
    line-height: 1.33;
    letter-spacing: -0.01em;
  `}
  /* Heading7 */
  ${props => props.heading7 && css`
    font-size: ${rem('20px')};
    line-height: 1.4;
    letter-spacing: -0.01em;
  `}
`;

export default Heading;