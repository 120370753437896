import React, { useState } from 'react';
//gatsby
import { Link, useStaticQuery, graphql } from 'gatsby';
//styles
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import vars from 'components/styles/varss';
//components
import Button from 'components/ui/button';
import Icon from 'components/ui/icon';
import Small from 'components/resources/small';
//grid
import { Row, Col } from 'styled-bootstrap-grid';

const DropdownWrap = styled.div`
  padding: 28px 0;
  > a {
    display: flex;
    align-items: center;
    padding: ${rem('5px')} ${rem('14px')};
    color: #fff;
    font-size: ${rem('16px')};
    line-height: 1.5;
    font-weight: bold;
    &:hover,
    &:focus {
      color: ${vars.colors.capri};
    }
    &:focus {
      text-decoration: underline;
      text-decoration-color: transparent;
    }
    /* current page */
    &[aria-current='page'] {
      color: #fff;
      &:hover,
      &:focus {
        color: ${vars.colors.capri};
      }
      &:focus {
        text-decoration: underline;
        text-decoration-color: transparent;
      }
    }
    .caret {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
    }
  }
  /* small */
  ${props => props.small && css`
    position: relative;
  `}
`;

const DropdownMenu = styled.div`
  position: absolute;
  margin: auto;
  top: ${rem('-5px')};
  transform: translateY(${vars.headerHeight}px);
  left: ${rem('40px')};
  right: ${rem('40px')};
  background-color: #fff;
  padding: ${rem('40px')};
  border-radius: 12px;
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.05);
  max-width: ${rem('840px')};
  z-index: 9;
  pointer-events: none;
  opacity: 0;
  transition: ${vars.transitions.hover2};
  /* active */
  ${props => props.active && css`
    pointer-events: auto;
    opacity: 1;
  `}
  /* small */
  ${props => props.small && css`
    padding: ${rem('18px')} ${rem('24px')};
    top: -1px;
    left: auto;
    right: ${rem('14px')};
    min-width: ${rem('234px')};
  `}
  .label {
    margin-bottom:  ${rem('8px')};
    font-size: ${rem('28px')};
    line-height: 1.12;
    letter-spacing: -0.01em;
    font-weight: 800;
  }
  p {
    max-width: ${rem('320px')};
    margin-bottom: ${rem('24px')};
  }
  .small-post {
    .img-wrap {
      @media (min-width: ${vars.media.xxlMin}) {
        height: 240px;
        img {
          height: 240px;
        }
      }
    }
  }
  /* plaform */
  &.platform {
    padding-bottom: ${rem('124px')};
    .row {
      > div {
        position: static;
      }
    }
  }
  /* solutions */
  &.solutions {
    max-width: ${rem('1240px')};
    .sublabel {
      margin-bottom: ${rem('16px')};
      font-size: ${rem('16px')};
      line-height: 1.5;
      font-weight: bold;
      color: ${vars.colors.grey5};
    }
  }
  /* resources */
  &.resources {
    .label {
      font-size: ${rem('16px')};
      line-height: 1.5;
      font-weight: bold;
      color: ${vars.colors.grey5};
    }
  }
`;

const DropdownLinks = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  > li {
    > a {
      display: flex;
      align-items: center;
      padding-top: ${rem('8px')};
      padding-bottom: ${rem('8px')};
      font-size: ${rem('18px')};
      font-weight: bold;
      line-height: 1.56;
      color: ${vars.colors.darkBlue};
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 36px;
        width: 36px;
        height: 36px;
        border-radius: 8px;
        background-color: ${vars.colors.grey1};
        margin-right: ${rem('16px')};
        svg {
          transition: none;
        }
      }
      &:hover {
        color: ${vars.colors.capri};
        .icon {
          background-color: ${vars.colors.paleCapri};
        }
      }
      &:focus {
        text-decoration: underline;
        text-decoration-color: transparent;
      }
      &.with-desc {
        align-items: start;
        div {
          p {
            font-weight: normal;
            font-size: ${rem('16px')};
            line-height: 1.5;
            margin-bottom: 0;
          }
        }
      }
      &.bottom-link {
        padding: ${rem('20px')} ${rem('40px')};
        background-color: ${vars.colors.grey1};
        position: absolute;
        margin: auto;
        left: ${rem('8px')};
        bottom: ${rem('8px')};
        right: ${rem('8px')};
        text-decoration: underline;
        border-radius: 12px;
        &:hover {
          .icon {
            background-color: transparent;
          }
        }
        &:focus {
          text-decoration: underline;
          text-decoration-color: transparent;
        }
        .icon {
          min-width: 0!important;
          width: auto!important;
          height: auto!important;
          svg {
            width: ${rem('10px')}!important;
            height: ${rem('10px')}!important;
          }
        }
      }
    }
  }
`;

const Dropdown = ({ menu, smallDropdown }) => {

  const [state, setState] = useState(false);

  //open
  const openMenu = () => {
    setState(true);
  }

  //close
  const closeMenu = () => {
    setState(false);
  }

  //latest article
  const data = useStaticQuery(graphql`
    query {
      allWpArticle(sort: {fields: date, order: DESC}, limit: 1, filter: {status: {eq: "publish"}}) {
        nodes {
          id
          title
          uri
          excerpt
          nodeType
          date(formatString: "MMMM DD, YYYY")
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 460, quality: 100)
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <DropdownWrap
      className="nav-dropdown"
      onMouseEnter={openMenu}
      onMouseLeave={closeMenu}
      small={smallDropdown}>
      <Link to="#">
        {menu.label}
        <span className="caret"><Icon icon="caret-down-small" size={8} /></span>
      </Link>
      {smallDropdown ? (
        <DropdownMenu active={state} small={smallDropdown}>
          <DropdownLinks>
            {menu.childItems.nodes.map((item) => (
              <li key={item.id}>
                {item.target === "_blank" ? (
                  <a href={item.path} target="_blank" rel="noreferrer" aria-label={item.label}>
                    {item.label}
                  </a>
                ) : (
                  <Link to={item.path} aria-label={item.label}>
                    {item.label}
                  </Link>
                )}
              </li>
            ))}
          </DropdownLinks>
        </DropdownMenu>
      ) : (
        menu.cssClasses[0] === 'platform-dropdown' ? (
          <DropdownMenu active={state} className="platform">
            <Row className="row">
              <Col col xs={6}>
                <p className="label">{menu.label}</p>
                <p className="p3">{menu.description}</p>
                <Button to={menu.path} green sm ariaLabel="See Overview">See Overview</Button>
              </Col>
              <Col col xs={6}>
                <DropdownLinks>
                  {menu.childItems.nodes.map((item) => (
                    <li key={item.id}>
                      <Link to={item.path} className={item.cssClasses} aria-label={item.label}>
                        <span className="icon">
                          <Icon icon={item.iconClass} size={18} />
                        </span>
                        {item.label}
                      </Link>
                    </li>
                  ))}
                </DropdownLinks>
              </Col>
            </Row>
          </DropdownMenu>
        ) : menu.cssClasses[0] === 'solutions-dropdown' ? (
          <DropdownMenu active={state} className="solutions">
            <Row>
              <Col col xs={6}>
                <p className="label">{menu.label}</p>
                <p className="p3">{menu.description}</p>
                <Button to={menu.path} green sm ariaLabel="Learn More">Learn More</Button>
              </Col>
              {menu.childItems.nodes.map((category) => (
                <Col col xs={6} key={category.id}>
                  <p className="sublabel">{category.label}</p>
                  <DropdownLinks>
                    {category.childItems.nodes.map((item) => (
                      <li key={item.id}>
                        <Link to={item.path} className={item.description ? 'with-desc' : null} aria-label={item.label}>
                          <span className="icon">
                            <Icon icon={item.iconClass} size={18} />
                          </span>
                          {item.description ? (
                            <div>
                              {item.label}
                              <p>{item.description}</p>
                            </div>
                          ) : (
                            item.label
                          )}
                        </Link>
                      </li>
                    ))}
                  </DropdownLinks>
                </Col>
              ))}
            </Row>
          </DropdownMenu>
        ) : menu.cssClasses[0] === 'resources-dropdown' ? (
          <DropdownMenu active={state} className="resources">
            <Row>
              <Col col xs={6}>
                <p className="label">{menu.label}</p>
                <DropdownLinks>
                  {menu.childItems.nodes.map((item) => (
                    <li key={item.id}>
                      {item.target === "_blank" ? (
                        <a href={item.path} target="_blank" rel="noreferrer" aria-label={item.label}>
                          <span className="icon">
                            <Icon icon={item.iconClass} size={18} />
                          </span>
                          {item.label}
                        </a>
                      ) : (
                        <Link to={item.path} aria-label={item.label}>
                          <span className="icon">
                            <Icon icon={item.iconClass} size={18} />
                          </span>
                          {item.label}
                        </Link>
                      )}
                    </li>
                  ))}
                </DropdownLinks>
              </Col>
              <Col col xs={6}>
                {data.allWpArticle.nodes.map((post) => (
                  <Small key={menu.id} post={post} noExcerpt noMargin />
                ))}
              </Col>
            </Row>
          </DropdownMenu>
        ) : menu.cssClasses[0] === 'partners-dropdown' ? (
          <DropdownMenu active={state} className="partners">
            <Row>
              <Col col xs={6}>
                <p className="label">{menu.label}</p>
                <p className="p3">{menu.description}</p>
              </Col>
              <Col col xs={6}>
                <DropdownLinks>
                  {menu.childItems.nodes.map((item) => (
                    <li key={item.id}>
                      {item.target === "_blank" ? (
                        <a href={item.path} target="_blank" rel="noreferrer" aria-label={item.label}>
                          <span className="icon">
                            <Icon icon={item.iconClass} size={18} />
                          </span>
                          {item.label}
                        </a>
                      ) : (
                        <Link to={item.path} aria-label={item.label} >
                          <span className="icon">
                            <Icon icon={item.iconClass} size={18} />
                          </span>
                          {item.label}
                        </Link>
                      )}
                    </li>
                  ))}
                </DropdownLinks>
              </Col>
            </Row>
          </DropdownMenu>
        ) : (
          <DropdownMenu active={state}>
            <Row>
              <Col col xs={6}>
                <p className="label">{menu.label}</p>
                <p className="p3">{menu.description}</p>
              </Col>
              <Col col xs={6}>
                <DropdownLinks>
                  {menu.childItems.nodes.map((item) => (
                    <li key={item.id}>
                      {item.target === "_blank" ? (
                        <a href={item.path} target="_blank" rel="noreferrer" aria-label={item.label}>
                          <span className="icon">
                            <Icon icon={item.iconClass} size={18} />
                          </span>
                          {item.label}
                        </a>
                      ) : (
                        <Link to={item.path} aria-label={item.label}>
                          <span className="icon">
                            <Icon icon={item.iconClass} size={18} />
                          </span>
                          {item.label}
                        </Link>
                      )}
                    </li>
                  ))}
                </DropdownLinks>
              </Col>
            </Row>
          </DropdownMenu>
        )
      )}
    </DropdownWrap>
  );
};

export default Dropdown;