import React, { useState } from 'react';
//gatsby
import { Link } from 'gatsby';
//styles
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import vars from 'components/styles/varss';
//components
import Icon from 'components/ui/icon';
//collapse
import { Collapse } from 'react-collapse';

const MobileNavCollapseWrap = styled.div`
  position: relative;
  color: #fff;
`;

const MobileNavCollapseHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${rem('20px')};
  font-weight: 800;
  line-height: 1.4;
  color: #fff;
  letter-spacing: -0.01em;
  cursor: pointer;
  &:hover {
    color: ${vars.colors.capri};
  }
  /* active */
  ${props => props.active && css`
    svg {
      display: block;
      transform: rotate(180deg);
    }
  `}
`

const MobileNavCollapseLinks = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  padding-top: ${rem('24px')};
  > li {
    margin-bottom: ${rem('10px')};
    > a {
      color: #fff;
      font-size: ${rem('16px')};
      line-height: 1.5;
      font-weight: bold;
      &:hover {
        color: ${vars.colors.capri};
      }
    }
  }
`;

const MobileNavCollapse = ({ menu }) => {

  const [state, setState] = useState(false);

  const toggleCollapse = () => {
    setState(!state);
  };

  return (
    <MobileNavCollapseWrap>
      <MobileNavCollapseHeader onClick={toggleCollapse} active={state}>
        <p>{menu.label}</p>
        <Icon icon="caret-down" size={12} />
      </MobileNavCollapseHeader>
      <Collapse isOpened={state}>
        <MobileNavCollapseLinks>
          {menu.childItems.nodes.map((item) => (
            <li key={item.id}>
              {item.target === '_blank' ? (
                <a href={item.path} target="_blank" rel="noreferrer">{item.label}</a>
              ) : (
                <Link to={item.path}>{item.label}</Link>
              )}
            </li>
          ))}
        </MobileNavCollapseLinks>
      </Collapse>
    </MobileNavCollapseWrap>
  );
};

export default MobileNavCollapse;