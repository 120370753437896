import React, { useContext, useEffect, useState } from 'react';
//context
import { GlobalContext } from 'context/';
//gatsby
import { Link, useStaticQuery, graphql } from 'gatsby';
//styles
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import vars from 'components/styles/varss';
//components
import MobileNavPanel from 'components/ui/mobileNavPanel';
import MobileNavCollapse from 'components/ui/mobileNavCollapse';
import Button from 'components/ui/button';
import Icon from 'components/ui/icon';
//hooks
import { useWindowSize } from 'components/hooks/useWindowSize';
//scroll lock
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
//device detect
import { isIOS } from 'react-device-detect';

//styled
const MobileNavWrap = styled.nav`
  position: fixed;
  margin: auto;
  top: ${vars.headerHeightSm}px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  opacity: 0;
  pointer-events: none;
  will-change: opacity;
  border-top: 1px solid ${vars.colors.darkBlue};
  /* active */
  ${props => props.active && css`
    opacity: 1;
    pointer-events: auto;
    transition: ${vars.transitions.hover1};
  `}
  /* menu active */
  ${props => props.panelActive && css`
    border-top-color: ${vars.colors.grey1};
  `}
`;

const MobileNavInner = styled.div`
  position: relative;
  overflow: hidden;
  background-color: ${vars.colors.darkBlue};
  height: calc(100vh - ${vars.headerHeightSm}px);
  height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
`;

const MobileNavContent = styled.div`
  overflow-y: auto;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: ${rem('40px')} ${rem('20px')} 0 ${rem('20px')};
  transition: ${vars.transitions.hover3};
  /* menu active */
  ${props => props.panelActive && css`
    transform: translateX(-100%);
  `}
`;

const MobileNavLinks = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  > li {
    margin-bottom: ${rem('32px')};
    > a {
      font-size: ${rem('20px')};
      font-weight: 800;
      line-height: 1.4;
      color: #fff;
      letter-spacing: -0.01em;
      /* Current Page */
      /* &[aria-current='page'] {
        color: ${vars.colors.green};
      } */
      &:hover {
        color: ${vars.colors.capri};
      }
      &:focus {
        text-decoration: underline;
        text-decoration-color: transparent;
      }
    }
  }
`;

const MobileNavWithMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${rem('20px')};
  font-weight: 800;
  line-height: 1.4;
  color: #fff;
  letter-spacing: -0.01em;
  cursor: pointer;
  transition: ${vars.transitions.hover1};
  &:hover {
    color: ${vars.colors.capri};
  }
`

const MobileNav = () => {

  //use Context
  const [context, setContext] = useContext(GlobalContext);

  useEffect(() => {
    if (!isIOS) {
      if (context.mobileNav === true) {
        disableBodyScroll(document.documentElement);
      } else {
        enableBodyScroll(document.documentElement);
        clearAllBodyScrollLocks();
      };
    }
  }, [context.mobileNav]);

  //close mobile nav
  const closeMobileNav = () => {
    setContext({
      ...context,
      mobileNav: false,
      mobilePanel: false,
      headerWhite: false,
    });
  };

  //open panel
  const openPanel = () => {
    setContext({
      ...context,
      mobilePanel: true,
      headerWhite: true,
    });
  };

  //close panel
  const closePanel = () => {
    setContext({
      ...context,
      mobilePanel: false,
      headerWhite: false,
    });
  };

  //panel menu state
  const [panelMenu, setPanelMenu] = useState({
    cssClasses: '',
    childItems: {
      nodes: []
    }
  });

  //update menus
  const updateMenu = (menu) => {
    setPanelMenu(menu);
  }

  ///////////////////////////////////////////////////////////////

  //window size
  const windowSize = useWindowSize();

  //window load & resize
  useEffect(() => {
    const windowResize = () => {
      if (windowSize.width > vars.navBreakpoint) {
        if (context.mobileNav === true) {
          closeMobileNav();
        }
      }
    }
    window.addEventListener('resize', windowResize);
    return () => {
      window.removeEventListener('resize', windowResize);
    };
  });

  //graphql query
  const data = useStaticQuery(graphql`
    query {
      Menu1: allWpMenuItem(filter: {locations: {eq: HEADER_MENU_CENTER}, parentDatabaseId: {eq: 0}}) {
        nodes {
          id
          label
          path
          description
          target
          cssClasses
          childItems {
            nodes {
              id
              label
              path
              description
              target
              cssClasses
              iconClass
              childItems {
                nodes {
                  id
                  label
                  path
                  description
                  target
                  cssClasses
                  iconClass
                }
              }
            }
          }
        }
      }
      Menu2: allWpMenuItem(filter: {locations: {eq: HEADER_MENU_RIGHT}, parentDatabaseId: {eq: 0}}) {
        nodes {
          id
          label
          path
          description
          target
          cssClasses
          iconClass
          childItems {
            nodes {
              id
              label
              path
              description
              target
              cssClasses
              iconClass
              childItems {
                nodes {
                  id
                  label
                  path
                  description
                  target
                  cssClasses
                  iconClass
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    windowSize.width < vars.navBreakpoint ? (
      <MobileNavWrap active={context.mobileNav} panelActive={context.mobilePanel}>
        <MobileNavInner>
          <MobileNavContent panelActive={context.mobilePanel}>
            <div>
              <MobileNavLinks>
                {data.Menu1.nodes.map((menu) => {
                  const withChildren = menu.childItems.nodes.length;
                  return (
                    <li key={menu.id}>
                      {withChildren ? (
                        <MobileNavWithMenu onClick={() => { updateMenu(menu); openPanel(); }} >
                          {menu.label}
                          <span className="caret">
                            <Icon icon="caret-right" size={12} />
                          </span>
                        </MobileNavWithMenu>
                      ) : (
                        <Link to={menu.path} onClick={closeMobileNav} aria-label={menu.label}>{menu.label}</Link>
                      )}
                    </li>
                  );
                })}
              </MobileNavLinks>
              <MobileNavLinks>
                {data.Menu2.nodes.map((menu) => {
                  const isBtn = menu.cssClasses[0] === 'is-btn';
                  const withChildren = menu.childItems.nodes.length;
                  return (
                    !isBtn ? (
                      <li key={menu.id}>
                        {withChildren ? (
                          <MobileNavCollapse menu={menu} />
                        ) : (
                          menu.target === "_blank" ? (
                            <a href={menu.path} target="_blank" rel="noreferrer" aria-label={menu.label}>
                              {menu.label}
                            </a>
                          ) : (
                            <Link to={menu.path} aria-label={menu.label}>
                              {menu.label}
                            </Link>
                          )
                        )}
                      </li>
                    ) : null
                  );
                })}
              </MobileNavLinks>
            </div>
            <MobileNavLinks>
              {data.Menu2.nodes.map((menu) => {
                const isBtn = menu.cssClasses[0] === 'is-btn';
                return (
                  isBtn ? (
                    <li key={menu.id} className="is-button">
                      <Button to={menu.path} target={menu.target} className="button" green block onClick={closeMobileNav}>
                        {menu.label}
                      </Button>
                    </li>
                  ) : null
                );
              })}
            </MobileNavLinks>
          </MobileNavContent>
          <MobileNavPanel open={context.mobilePanel} close={closePanel} menu={panelMenu} />
        </MobileNavInner>
      </MobileNavWrap>
    ) : null
  );
};

export default MobileNav;