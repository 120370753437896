import React from 'react';
//gatsby
import { Link, useStaticQuery, graphql } from 'gatsby';
//styles
import styled from 'styled-components';
import { rem, rgba } from 'polished';
import vars from 'components/styles/varss';
//components
import Container from 'components/ui/container';
import Button from 'components/ui/button';
import Icon from 'components/ui/icon';
import FooterCollapseMenu from 'components/ui/footerCollapseMenu';
//grid
import { Row, Col } from 'styled-bootstrap-grid';
//parser
import parse from "html-react-parser";
//hooks
import { useWindowSize } from 'components/hooks/useWindowSize';

//styled
const FooterWrap = styled.footer`
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    bottom: calc(100% - 1px);
    transform: scaleY(-1);
    left: 0;
    z-index: 9;
    height: ${rem('150px')};
    width: 100%;
    mask-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQ0MCIgaGVpZ2h0PSIxNTIiIHZpZXdCb3g9IjAgMCAxNDQwIDE1MiIgZmlsbD0ibm9uZSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyNi41OTcgMTM4Ljc0Qzk5Ljg4NjcgMTI3LjM2IDc2LjY0NzkgMTA5LjE2NCA1OS4yMTYxIDg1Ljk3OThMMCAzLjA1MTc2ZS0wNUwxNDQwIDBDMTQ0MCAwIDE0MTkuOTggMjUuODQwNCAxMzgwLjE1IDMyLjk1ODRMMjExLjM4MiAxNTAuODExQzE4Mi41NDkgMTU0LjI4MyAxNTMuMzA4IDE1MC4xMiAxMjYuNTk3IDEzOC43NFoiIGZpbGw9IiMwNzFBMkIiLz4KPC9zdmc+Cg==");
    mask-size: 100% 100%;
    mask-repeat: no-repeat;
    mask-position: top;
    pointer-events: none;
    background-color: ${vars.colors.darkBlue};
    @media(max-width: ${vars.media.smMax}) {
      height: ${rem('100px')};
    }
    @media(max-width: ${vars.media.xsMax}) {
      height: ${rem('50px')};
    }
  }
`;

const FooterContent = styled.footer`
  background-color: ${vars.colors.darkBlue};
  color: #fff;
  padding-top: ${rem('10px')};
  @media(max-width: ${vars.media.smMax}) {
    padding-top: ${rem('35px')};
  }
  .row {
    > div {
      margin-bottom: ${rem('60px')};
    }
  }
  p {
    @media(max-width: ${vars.media.smMax}) {
      max-width: ${rem('400px')};
    }
    + p {
      margin-top: ${rem('50px')};
    }
  }
`;

const FooterLogo = styled.img`
  display: block;
  margin-bottom: ${rem('24px')};
  @media(max-width: ${vars.media.smMax}) {
    width: 166px;
    height: 30px;
  }
`;

const FooterSubscribe = styled.div`
  margin-top: ${rem('32px')};
  margin-bottom: ${rem('40px')};
  position: relative;
  max-width: ${rem('284px')};
  label {
    display: none;
  }
  input {
    display: block;
    width: 100%;
    height: ${rem('48px')};
    border-radius: 12px;
    border: none;
    font-size: ${rem('16px')};
    color: ${rgba(vars.colors.darkBlue, 0.5)};
    padding: 12px 80px 12px 14px;
    font-family: ${vars.fonts.fontFamily};
  }
  .button {
    position: absolute;
    top: ${rem('4px')};
    bottom: ${rem('4px')};
    right: ${rem('4px')};
    margin: auto;
  }
`;

const FooterSocial = styled.div`
  .social-heading {
    font-size: ${rem('16px')};
    font-weight: bold;
    line-height: 1.5;
    margin-bottom: ${rem('20px')};
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    li {
      &:not(:last-child) {
        margin-right: ${rem('12px')};;
      }
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: ${rem('40px')};
        height: ${rem('40px')};
        border-radius: 12px;
        background-color: ${rgba('#fff', 0.1)};
        color: #fff;
        font-size: 0;
        &:hover {
          background-color: ${rgba('#fff', 0.3)};
          color: ${vars.colors.capri};
        }
        &:focus {
          text-decoration: underline;
          text-decoration-color: transparent;
        }
      }
    }
  }
`;

const FooterLinks = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  > li {
    margin-bottom: ${rem('32px')};
    > a {
      display: block;
      font-size: ${rem('16px')};
      line-height: 1.5;
      font-weight: bold;
      color: #fff;
      &:hover {
        color: ${vars.colors.capri};
      }
      &:focus {
        text-decoration: underline;
        text-decoration-color: transparent;
      }
    }
    > ul {
      list-style: none;
      margin: 0;
      padding: 0;
      padding-top: ${rem('32px')};
      li {
        margin-bottom: ${rem('16px')};
        a {
          display: block;
          font-size: ${rem('16px')};
          line-height: 1.5;
          color: #fff;
          &:hover {
            color: ${vars.colors.capri};
          }
          &:focus {
            text-decoration: underline;
            text-decoration-color: transparent;
          }
        }
      }
    }
  }
`;

const Footer = () => {

  //graphql query
  const data = useStaticQuery(graphql`
    query {
      wp {
        generalSettings {
          title
        }
        footerInfo {
          footer_nl_text
          footer_text
        }
        socialLinks {
          facebook_link
          instagram_link
          linkedin_link
          twitter_link
        }
      }
      FooterMenu1: allWpMenuItem(filter: {locations: {eq: FOOTER_MENU_1}, parentDatabaseId: {eq: 0}}) {
        nodes {
          id
          label
          path
          target
          childItems {
            nodes {
              id
              label
              path
              target
            }
          }
        }
      }
      FooterMenu2: allWpMenuItem(filter: {locations: {eq: FOOTER_MENU_2}, parentDatabaseId: {eq: 0}}) {
        nodes {
          id
          label
          path
          target
          childItems {
            nodes {
              id
              label
              path
              target
            }
          }
        }
      }
      FooterMenu3: allWpMenuItem(filter: {locations: {eq: FOOTER_MENU_3}, parentDatabaseId: {eq: 0}}) {
        nodes {
          id
          label
          path
          target
          childItems {
            nodes {
              id
              label
              path
              target
            }
          }
        }
      }
      Logo: file(relativePath: {eq: "Refersion-logo.svg"}) {
        publicURL
      }
      Shape: file(relativePath: {eq: "footer-shape.svg"}) {
        publicURL
      }
    }
  `);

  //window size
  const windowSize = useWindowSize();

  return (
    <FooterWrap>
      
      <FooterContent>
        <Container>
          <Row className="row">
            <Col col xs={12} sm={12} md={4} lg={3}>
              <Link to="/">
                <FooterLogo src={data.Logo.publicURL} alt={data.wp.generalSettings.title} />
              </Link>
              <p className="p3">
                {parse(data.wp.footerInfo.footer_text.replace(/\n/g, '<br>\n'))}
              </p>
              <p className="p3">
                {parse(data.wp.footerInfo.footer_nl_text.replace(/\n/g, '<br>\n'))}
              </p>
              <FooterSubscribe>
                <label htmlFor="footer-subcscribe">Email</label>
                <input type="email" placeholder="Your Email" id="footer-subcscribe" />
                <Button xs darkBlue as="button">Submit</Button>
              </FooterSubscribe>
              <FooterSocial>
                <p social-heading>Follow Us</p>
                <ul>
                  <li>
                    <a href={data.wp.socialLinks.twitter_link} target="_blank" rel="noreferrer" aria-label="Twitter Link">
                      <Icon icon="twitter" size={18} aria-label="Twitter" />
                    </a>
                  </li>
                  <li>
                    <a href={data.wp.socialLinks.facebook_link} target="_blank" rel="noreferrer" aria-label="Facebook Link">
                      <Icon icon="facebook" size={18} aria-label="Facebook" />
                    </a>
                  </li>
                  <li>
                    <a href={data.wp.socialLinks.linkedin_link} target="_blank" rel="noreferrer" aria-label="LinkedIn link">
                      <Icon icon="linkedin" size={18} aria-label="LinkedIn" />
                    </a>
                  </li>
                  <li>
                    <a href={data.wp.socialLinks.instagram_link} target="_blank" rel="noreferrer" aria-label="Instagram Link">
                      <Icon icon="instagram" size={20} aria-label="Instagram" />
                    </a>
                  </li>
                </ul>
              </FooterSocial>
            </Col>
            {windowSize.width < vars.footerBreakpoint ? (
              <Col col xs={12}>
                {data.FooterMenu1.nodes.map((menu) => (
                  <FooterCollapseMenu menu={menu} />
                ))}
                {data.FooterMenu2.nodes.map((menu) => (
                  <FooterCollapseMenu menu={menu} />
                ))}
                {data.FooterMenu3.nodes.map((menu) => (
                  <FooterCollapseMenu menu={menu} />
                ))}
              </Col>
              ) : (
                <Col col xs={12} md={8} lg={8} lgOffset={1}>
                  <Row>
                    <Col col xs={12} sm={4}>
                      <FooterLinks>
                        {data.FooterMenu1.nodes.map((menu) => (
                          <li key={menu.id}>
                            <Link to={menu.path}>{menu.label}</Link>
                            {menu.childItems.nodes.length ? (
                              <ul>
                                {menu.childItems.nodes.map((item) => (
                                  <li key={item.id}>
                                    {item.target === '_blank' ? (
                                      <a href={item.path} target="_blank" rel="noreferrer" aria-label={item.label}>{item.label}</a>
                                    ) : (
                                      <Link to={item.path} aria-label={item.label}>{item.label}</Link>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            ) : null}
                          </li>
                        ))}
                      </FooterLinks>
                    </Col>
                    <Col col xs={12} sm={4}>
                      <FooterLinks>
                        {data.FooterMenu2.nodes.map((menu) => (
                          <li key={menu.id}>
                            <Link to={menu.path}>{menu.label}</Link>
                            {menu.childItems.nodes.length ? (
                              <ul>
                                {menu.childItems.nodes.map((item) => (
                                  <li key={item.id}>
                                    {item.target === '_blank' ? (
                                      <a href={item.path} target="_blank" rel="noreferrer" aria-label={item.label}>{item.label}</a>
                                    ) : (
                                      <Link to={item.path} aria-label={item.label}>{item.label}</Link>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            ) : null}
                          </li>
                        ))}
                      </FooterLinks>
                    </Col>
                    <Col col xs={12} sm={4}>
                      <FooterLinks>
                        {data.FooterMenu3.nodes.map((menu) => (
                          <li key={menu.id}>
                            <Link to={menu.path}>{menu.label}</Link>
                            {menu.childItems.nodes.length ? (
                              <ul>
                                {menu.childItems.nodes.map((item) => (
                                  <li key={item.id}>
                                    {item.target === '_blank' ? (
                                      <a href={item.path} target="_blank" rel="noreferrer" aria-label={item.label}>{item.label}</a>
                                    ) : (
                                      <Link to={item.path} aria-label={item.label}>{item.label}</Link>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            ) : null}
                          </li>
                        ))}
                      </FooterLinks>
                    </Col>
                  </Row>
                </Col>
              )}
          </Row>
        </Container>
      </FooterContent>
    </FooterWrap>
  );
};

export default Footer;