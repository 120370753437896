const vars = {
  colors: {
    text: '#071A2B',
    green: '#54DE62',
    blue: '#112E45',
    darkBlue: '#071A2B',
    paleGreen: '#BBFABB',
    darkGreen: '#12824E',
    capri: '#1BB3F7',
    paleCapri: '#BEEBFA',
    purple: '#907AFF',
    palePurple: '#E9D7FA',
    red: '#FF4848',
    orange: '#FF7C53',
    yellow: '#F2C100',
    grey1: '#F4F4F6',
    grey2: '#E8E8E8',
    grey3: '#DEDEDE',
    grey4: '#ABABAB',
    grey5: '#7A7A7A',
    grey6: '#5A5A5A',
  },
  fonts: {
    fontSize: '1.25rem',
    fontFamily: 'Modern Era, "Modern Era", Roboto, system, -apple-system, BlinkMacSystemFont, ".SFNSDisplay-Regular", "Helvetica Neue", Helvetica, Arial, sans-serif',
    fontWeight: 400,
    lineHeight: 1.6,
  },
  media: {
    smMin: '576px',
    mdMin: '768px',
    lgMin: '992px',
    xlMin: '1200px',
    xxlMin: '1580px',
    xsMax: '575px',
    smMax: '767px',
    mdMax: '991px',
    lgMax: '1199px',
    xlMax: '1579px',
  },
  transitions: {
    hover1: 'all 0.2s ease-in-out 0s',
    hover2: 'color 0.2s ease-in-out 0s, background-color 0.2s ease-in-out 0s, border 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s',
    hover3: 'transform 0.3s ease-in-out 0s',
  },
  maxWidth: '1272px',
  navBreakpoint: 1199,
  footerBreakpoint: 574,
  headerHeight: 92,
  headerHeightSm: 72,
}

export default vars;