import React, { useState } from 'react';
//gatsby
import { Link } from 'gatsby';
//styles
import styled, { css } from 'styled-components';
import { rem, rgba } from 'polished';
import vars from 'components/styles/varss';
//components
import Icon from 'components/ui/icon';
//collapse
import { Collapse } from 'react-collapse';

const FooterCollapseMenuWrap = styled.div`
  position: relative;
  padding-bottom: ${rem('20px')};
  margin-bottom: ${rem('20px')};
  border-bottom: 1px solid ${rgba('#fff', 0.2)};
  > {
    a {
      display: block;
      color: #fff;
      font-size: ${rem('16px')};
      line-height: 1.5;
      font-weight: bold;
      line-height: 1.5;
      &:hover {
        color: ${vars.colors.capri};
      }
    }
  }
`;

const FooterCollapseMenuHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  p {
    font-size: ${rem('16px')};
    line-height: 1.5;
    font-weight: bold;
  }
  /* active */
  ${props => props.active && css`
    svg {
      display: block;
      transform: rotate(180deg);
    }
  `}
`;

const FooterCollapseMenuLinks = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  padding-top: ${rem('10px')};
  > li {
    margin-bottom: ${rem('10px')};
    a {
      color: #fff;
      font-size: ${rem('16px')};
      line-height: 1.5;
      &:hover {
        color: ${vars.colors.capri};
      }
      &:focus {
        text-decoration: underline;
        text-decoration-color: transparent;
      }
    }
  }
`;

const FooterCollapseMenu = ({ menu }) => {

  const [state, setState] = useState(false);

  const toggleCollapse = () => {
    setState(!state);
  };

  return (
    <FooterCollapseMenuWrap>
      {menu.childItems.nodes.length ? (
        <>
          <FooterCollapseMenuHeader onClick={toggleCollapse} active={state}>
            <p>{menu.label}</p>
            <Icon icon="caret-down" size={12} />
          </FooterCollapseMenuHeader>
          <Collapse isOpened={state}>
            <FooterCollapseMenuLinks>
              {menu.childItems.nodes.map((item) => (
                <li key={item.id}>
                  {item.target === '_blank' ? (
                    <a href={item.path} target="_blank" rel="noreferrer">{item.label}</a>
                  ) : (
                    <Link to={item.path}>{item.label}</Link>
                  )}
                </li>
              ))}
            </FooterCollapseMenuLinks>
          </Collapse>
        </>
      ) : (
        <Link to={menu.path}>{menu.label}</Link>
      )}
    </FooterCollapseMenuWrap>
  );
};

export default FooterCollapseMenu;