import React from 'react';
//gatsby
import { Link } from 'gatsby';
//gatsby image
import { GatsbyImage, getImage } from "gatsby-plugin-image"
//styles
import styled, { css } from 'styled-components';
import vars from 'components/styles/varss';
import { rem } from 'polished';
//components
import Heading from 'components/ui/heading';
import Icon from 'components/ui/icon';
//parser
import parse from "html-react-parser";

//styled
const SmallWrap = styled.div`
  margin-bottom: ${rem('50px')};
  /* &:hover {
    h3 {
      text-decoration: underline!important;
    }
  } */

  > a {
    display: block;
    text-decoration: none;
  }
  .img-wrap {
    position: relative;
    height: ${rem('240px')};
    background-color: ${vars.colors.grey1};
    border-radius: ${rem('12px')};
    overflow: hidden;
    margin-bottom: ${rem('32px')};
    @media (min-width: ${vars.media.xxlMin}) {
      height: ${rem('280px')};
    }
    .gatsby-image-wrapper {
      display: block;
      pointer-events: none;
      img {
        width: 100%;
        height: ${rem('240px')};
        border-radius: ${rem('12px')};
        object-fit: cover;
        object-position: center;
        transform: translateZ(0)!important;
        @media (min-width: ${vars.media.xxlMin}) {
          height: ${rem('280px')};
        }
      }
    }
    .logo {
      background-color: #fff;
      padding: ${rem('16px')} ${rem('20px')};
      border-radius: ${rem('12px')};
      position: absolute;
      top: ${rem('24px')};
      left: ${rem('24px')};
      z-index: 2;
      max-width: ${rem('200px')};
      img {
        display: block;
        max-width: 100%;
        height: auto;
        width: 100%;
      }
    }
  }
  .categories {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    li {
      margin-right: ${rem('15px')};
      a {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .category {
    font-weight: bold;
    line-height: 1.6;
    color: ${vars.colors.capri};
    letter-spacing: -0.008em;
    margin-bottom: ${rem('8px')};
  }
  h3 {
    &:hover {
      text-decoration: underline;
    }
  }
  .quote {
    margin-top: ${rem('16px')};
    margin-bottom: ${rem('16px')};
    q {
      display: block;
      font-size: ${rem('16px')};
      line-height: 1.5;
      font-weight: bold;
      padding-left: ${rem('14px')};
      border-left: 2px solid ${vars.colors.purple};
      margin-bottom: ${rem('16px')};
      &:before,
      &:after {
        display: none;
      }
    }
    cite {
      display: block;
      font-size: ${rem('16px')};
      line-height: 1.5;
      font-style: normal;
      padding-left: ${rem('14px')};
    }
  }
  .read {
    display: flex;
    align-items: center;
    color: ${vars.colors.capri};
    font-weight: bold;
    font-size: ${rem('16px')};
    line-height: 1.5;
    text-decoration: underline;
    .icon {
      margin-left: ${rem('10px')};
    }
  }
  /* no excerpt */
  ${props => props.noExcerpt && css`
    h3 {
      margin-bottom: 0;
    }
  `}
  /* no margin */
  ${props => props.noMargin && css`
    margin-bottom: 0;
  `}
`;

const SmallExcerpt = styled.div`
  > p {
    font-size: ${rem('18px')};
    line-height: 1.56;
  }
  /* Case Study */
  ${props => props.caseStudy && css`
    > p {
      font-size: ${rem('16px')};
      line-height: 1.50;
    }
  `}
`;

const Small = ({ post, noExcerpt, noMargin, withCategory }) => {

  return (
    <SmallWrap className="small-post" noMargin={noMargin} noExcerpt={noExcerpt}>
      {post.nodeType === 'Article' ? (
        <>
          <Link to={`${post.uri}`}>
            <div className="img-wrap">
              {post.featuredImage &&
                <GatsbyImage image={getImage(post.featuredImage.node.localFile.childImageSharp)} alt={post.title} />
              }
            </div>
          </Link>
          {withCategory ? (
            <ul className="categories">
              {post.articleCategories.nodes.map((category) => (
                <li key={category.id}>
                  <Link to={category.uri} className="category">{category.name}</Link>
                </li>
              ))}
            </ul>
          ) : (
            <p className="category">Blog</p>
          )}
          <Link to={`${post.uri}`}>
            <Heading heading6 as={'h3'} marginBottom="8px">{post.title}</Heading>
            <SmallExcerpt>
              {!noExcerpt && parse(post.excerpt)}
            </SmallExcerpt>
          </Link>
        </>
      ) : post.nodeType === 'CaseStudy' ? (
        <Link to={`${post.uri}`}>
          <div className="img-wrap">
            {post.featuredImage &&
              <GatsbyImage image={getImage(post.featuredImage.node.localFile.childImageSharp)} alt={post.title} />
            }
            {post.csMeta.logo && <div className="logo"><img src={post.csMeta.logo} alt="logo" /></div>}
          </div>
          <Heading heading6 as={'h3'} marginBottom="12px">{post.title}</Heading>
          <SmallExcerpt caseStudy>
            {!noExcerpt && parse(post.excerpt)}
          </SmallExcerpt>
          <blockquote className="quote">
            <q>{parse(post.csMeta.quoteText)} </q>
            <cite>
              <strong>— {post.csMeta.quoteName},</strong> {post.csMeta.quoteRole}
            </cite>
          </blockquote>
          <span className="read">Read Case Study <Icon icon="arrow-right" size={14} className="icon" /></span>
        </Link>
      ) : null}
    </SmallWrap>
  );
}

export default Small;