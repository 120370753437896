import React from 'react';
//router
import { useLocation } from '@reach/router';
//style
import styled, { createGlobalStyle } from 'styled-components';
import reset from 'components/styles/reset';
import typography from 'components/styles/typography';
import icons from 'components/styles/icons';
import vars from 'components/styles/varss';
//page transition
import PageTransition from 'components/ui/pageTransition';
//components
import Header from "components/ui/header";
import MobileNav from "components/ui/mobileNav";
import Footer from "components/ui/footer";
//utils
import OtherScripts from '../utils/otherScripts';
import Seo from '../utils/seo';
//grid
import { GridThemeProvider } from 'styled-bootstrap-grid';
//wordpress css
import 'assets/css/wordpress/block-library/build-style/style.css';
import 'assets/css/wordpress/block-library/build-style/theme.css';
//external css
import 'assets/css/main.min.css';
import 'assets/css/iconfont.css';
import 'assets/css/gatsby.scss';

//Reset & Default Styles
const GlobalStyle = createGlobalStyle`
  ${reset};
  ${typography};
  ${icons};
`;

//grid settings
const gridTheme = {
  row: {
    padding: 16, // default 15
  },
  col: {
    padding: 16, // default 15
  },
  container: {
    padding: 16, // default 15
  },
};

//styled
const LayoutWrap = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: ${vars.headerHeight}px;
  @media (max-width: ${vars.navBreakpoint}px) {
    padding-top: ${vars.headerHeightSm}px;
  }
`;

//styled
const Main = styled.main`
  position: relative;
  min-height: 100vh;
`;

const Layout = ({ children, isResources, headerBg, headerColor }) => {

  //location
  const location = useLocation();

  return (
    <LayoutWrap>
      <Seo>
        <OtherScripts />
        <GlobalStyle />
        <GridThemeProvider gridTheme={gridTheme}>
          <Header
            isResources={isResources}
            headerBgFromPage={headerBg}
            headerColorFromPage={headerColor}
          />
          <MobileNav isResources={isResources} />
          <Main className={!isResources ? 'wp-front' : null}>
            <PageTransition location={location.pathname}>
              {children}
            </PageTransition>
          </Main>
          <Footer />
        </GridThemeProvider>
      </Seo>
    </LayoutWrap>
  );
};

export default Layout;